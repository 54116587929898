import React, { Component } from "react";
import Header from "../DefaultLayout/Header";
import Footer from "../DefaultLayout/Footer";
import axios from 'axios';
import Noty from 'noty';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      phone: '',
      email: '',
      subject: '',
      message: '',
    }
  }
  handleSubmit(event) {
    event.preventDefault();
    console.log(this.state)
    axios({
      method: "POST", 
      url:"http://localhost:3002/send", 
      data:  this.state
    }).then((response)=>{
      if (response.data.status === 'success') {
        new Noty({
          type: 'success',
          layout: 'topRight',
          theme: 'nest',
          text: 'We got your message!! We will get in touch with you soon! 🤖',
          timeout: '4000',
          progressBar: true,
          closeWith: ['click'],
       }).show(); 
        this.resetForm()
      } else if(response.data.status === 'fail') {
        new Noty({
          type: 'error',
          layout: 'topRight',
          theme: 'nest',
          text: 'Something weird happened while sending your message. Please try again in a moment. 🤖',
          timeout: '4000',
          progressBar: true,
          closeWith: ['click'],
       }).show();
      }
    })
  }
  resetForm (){
    this.setState({name: '', email: '', message: '', phone:'', subject:''})
  }
  render() {
    return (
      <div>
          <Header/>
        <section className="hero">
          <div className="container text-center">
            <nav
              aria-label="breadcrumb"
              className="d-flex justify-content-center"
            >
              <ol className="breadcrumb">
                
                <li aria-current="page" className="breadcrumb-item active">
                  Contact{" "}
                </li>
              </ol>
            </nav>
            <h1 className="hero-heading">Get in touch with us!</h1>
            <div className="row">
              <div className="col-lg-7 mx-auto">
                <p className="lead text-muted mt-2">
                  We're always available for any questions or concerns you'd have. Reach us via any of the channels below.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <header className="section-header">
              <h2 className="mb-2">Have specific questions and concerns about TracGlobal?</h2>
              <p className="lead">
                You can check out our <a href="/faqs">FAQs Page</a> or Let us know what's on your mind below.
              </p>
            </header>
            <div className="row align-items-center mt-5">
              <div className="col-lg-7">
                <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST" className="contact-form text-left">
                  <div className="form-group mb-4">
                    <label>
                      Name<sup className="text-primary">&#10033;</sup>
                    </label>
                    <input
                      value={this.state.name} 
                      onChange={this.onNameChange.bind(this)}
                      type="text"
                      name="name"
                      placeholder="e.g. Adjoa Diana"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group mb-4">
                    <label>
                      Your Email<sup className="text-primary">&#10033;</sup>
                    </label>
                    <input
                      value={this.state.email} 
                      onChange={this.onEmailChange.bind(this)}
                      type="text"
                      name="email"
                      placeholder="e.g. name@gmail.com"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group mb-4">
                    <label>
                      Your Phone<sup className="text-primary">&#10033;</sup>
                    </label>
                    <input
                      value={this.state.phone} 
                      onChange={this.onPhoneChange.bind(this)}
                      type="phone"
                      name="phone"
                      placeholder="e.g. +233 200 200 000"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group mb-4">
                    <label>
                      Subject<sup className="text-primary">&#10033;</sup>
                    </label>
                    <input
                      value={this.state.subject} 
                      onChange={this.onSubjectChange.bind(this)}
                      type="text"
                      name="subject"
                      placeholder="What's your question/concern about?(e.g Map Coverage)"
                      maxLength="25"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group mb-4">
                    <label>
                      Now tell us the details
                      <sup className="text-primary">&#10033;</sup>
                    </label>
                    <textarea
                      value={this.state.message} 
                      onChange={this.onMessageChange.bind(this)}
                      name="message"
                      placeholder="Go a bit deeper! Headsup!! You have 250 characters"
                      className="form-control"
                      maxLength="250"
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <input
                      type="submit"
                      value="Send"
                      className="btn btn-primary"
                    />
                  </div>
                </form>
              </div>
              <div className="col-lg-5 contact-details mt-5 mt-lg-0">
                <div className="box">
                  <div className="icon d-flex align-items-end">
                    <img
                      src="assets/img/placeholder.svg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h5>Address</h5>
                  <p className="text-small font-weight-light">
                    No.19 Banana Street, Ambassadorial Enclave, East Legon, Accra-Ghana
                  </p>
                </div>
                <div className="box">
                  <div className="icon d-flex align-items-end">
                    <img
                      src="assets/img/technology.svg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h5>Call/Whatsapp Center</h5>
                  <p className="text-small font-weight-light">
                    You can reach us via phone on the number below. Your network operator's charges may apply. We advice you to use this channel ONLY if your concern is urgent.
                  </p>
                  <strong className="text-muted">+233 205 851 935 | +233 268 297 280</strong>
                </div>
                <div className="box">
                  <div className="icon d-flex align-items-end">
                    <img
                      src="assets/img/multimedia.svg"
                      alt="chat"
                      className="img-fluid"
                    />
                  </div>
                  <h5>Electronic support/ Chat</h5>
                  <p className="text-small font-weight-light">
                    Please feel free to write an email to us with the addresses below or Chat with an agent <code>(Bottom right corner of the page)</code>.
                  </p>
                  <ul className="text-left">
                    <li>
                      <a
                        href="mailto:info@tracglobal.live"
                        className="text-small"
                      >
                        info@tracglobal.live
                      </a>
                    </li>
                    <li>
                      <a
                        href="mailto:support@tracglobal.live"
                        className="text-small"
                      >
                        support@tracglobal.live
                      </a>
                    </li>
                   
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="bg-gray">
          <div className="container">
            <div className="text-center">
              <h2>Trusted by teams everywhere</h2>
              <div className="row">
                <div className="col-lg-8 mx-auto">
                  <p className="lead text-muted mt-2">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    sed do eiusmod tempor incididunt..
                  </p>
                </div>
              </div>
            </div>
            <div className="clients mt-5">
              <div className="row">
                <div className="col-lg-2">
                  <img
                    src="https://d19m59y37dris4.cloudfront.net/appton/1-1/img/client-1.svg"
                    alt=""
                    className="client-image img-fluid"
                  />
                </div>
                <div className="col-lg-2">
                  <img
                    src="https://d19m59y37dris4.cloudfront.net/appton/1-1/img/client-2.svg"
                    alt=""
                    className="client-image img-fluid"
                  />
                </div>
                <div className="col-lg-2">
                  <img
                    src="https://d19m59y37dris4.cloudfront.net/appton/1-1/img/client-3.svg"
                    alt=""
                    className="client-image img-fluid"
                  />
                </div>
                <div className="col-lg-2">
                  <img
                    src="https://d19m59y37dris4.cloudfront.net/appton/1-1/img/client-4.svg"
                    alt=""
                    className="client-image img-fluid"
                  />
                </div>
                <div className="col-lg-2">
                  <img
                    src="https://d19m59y37dris4.cloudfront.net/appton/1-1/img/client-5.svg"
                    alt=""
                    className="client-image img-fluid"
                  />
                </div>
                <div className="col-lg-2">
                  <img
                    src="https://d19m59y37dris4.cloudfront.net/appton/1-1/img/client-6.svg"
                    alt=""
                    className="client-image img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <Footer/>
      </div>
    );
    
  }
  onNameChange(event) {
    this.setState({name: event.target.value})
  }
  onPhoneChange(event) {
    this.setState({phone: event.target.value})
  }
  onSubjectChange(event) {
    this.setState({subject: event.target.value})
  }

  onEmailChange(event) {
    this.setState({email: event.target.value})
  }

  onMessageChange(event) {
    this.setState({message: event.target.value})
  }
}
export default Contact;
