import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./screens/Home";
import Contact from "./screens/Contact";
import About from "./screens/About";
import FAQs from "./screens/FAQs";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import Terms from "./screens/Terms";
import Businesses from './screens/Businesses';



function App() {
  return (
    <Router>
            <div>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/contact" component={Contact} />
                    <Route path="/about" component={About} />
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                    <Route path="/terms" component={Terms} />
                    <Route path="/faqs" component={FAQs} />
                    <Route path="/businesses" component={Businesses} />
                </Switch>
            </div>
        </Router>
  );
}

export default App;
