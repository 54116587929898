import React,{Component} from 'react';

class Terms extends Component{
    render(){
        return(
            <div>
                Hello Terms
            </div>
        )
    }
}
export default Terms;