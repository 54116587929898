import React,{Component} from 'react';

class PrivacyPolicy extends Component{
    render(){
        return(
            <div>
                Hello Privacy
            </div>
        )
    }
}
export default PrivacyPolicy;