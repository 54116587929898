import React, {Component} from 'react';
import {Link } from 'react-router-dom';


class Header extends Component{
    render(){
        return(
          
            <header className="header">
            <nav className="navbar navbar-expand-lg fixed-top">
              <div className="container"><a href="./" className="navbar-brand"><img src="assets/img/logo.png" alt="front" className="img-fluid"/></a>
                <button type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" className="navbar-toggler navbar-toggler-right">Menu<i className="fa fa-bars ml-2"></i></button>
                <div id="navbarSupportedContent" className="collapse navbar-collapse">
                  <ul className="navbar-nav ml-auto">
                        
                        <li className="nav-item"> <Link to="/" className="nav-link active">Home</Link></li>
                        
                        <li className="nav-item"> <Link to="/faqs" className="nav-link active">FAQ</Link></li>
                        
                        <li className="nav-item"> <Link to="/contact" className="nav-link active">Contact</Link></li>
                        
{/* .                    <li className="nav-item dropdown"><a id="pages" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle">Dropdown</a>
                      <div className="dropdown-menu"><a href="faq.html" className="dropdown-item">FAQ</a><a href="contact.html" className="dropdown-item">Contact</a><a href="text.html" className="dropdown-item">Text Page</a></div>
                    </li> */}
                  </ul><a href="/app/" target="_blank" className="btn btn-primary navbar-btn ml-0 ml-lg-3">Go To App </a>
                </div>
              </div>
            </nav>
          </header>
        )
    }
}

export default Header;