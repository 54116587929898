import React, { Component } from "react";
import Header from "../DefaultLayout/Header";
import Footer from "../DefaultLayout/Footer";
import axios from 'axios';
import Noty from 'noty';
import "./home.css";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: '',
      email: null,
    };
  }

  handleSubmitPhone(event) {
    event.preventDefault();
    console.log(this.state)
    axios({
      method: "POST", 
      url:"http://localhost:3002/phone", 
      data:  this.state
    }).then((response)=>{
      if (response.data.status === 'success') {
        alert("Message sent successfully")
        this.resetForm()
      } else if(response.data.status === 'fail') {
       alert("Sorry but we couldn't schedule a call at this time! Please try again later or send us an email via info@tracglobal.live")
      }
    })
  }

  resetForm (){
    this.setState({ phone:''})
  }

  render() {
    return (
      <div>
        <Header />
        <section className="hero">
          <div className="container mb-5">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <h1 className="hero-heading mb-0">
                  Vehicle & Asset Tracking for your <br />{" "}
                  <span style={{backgroundColor:'#ffc500'}}
                    className="typer"
                    id="main"
                    data-words="Private car, Taxi,Motorcycle,Uber,Tractor,Delivery van,Fleet,Asset Rentals"
                    data-delay="100"
                    data-deletedelay="1000"
                  ></span>
                  <span className="cursor" data-owner="main"></span>
                </h1>
                <i
                  className="glyphicon glyphicon-play whiteText"
                  aria-hidden="true"
                ></i>
                <span className="ripple pinkBg"></span>
                <span className="ripple pinkBg"></span>
                <span className="ripple pinkBg"></span>
                <div className="row">
                  <div className="col-lg-10">
                    <p className="lead text-muted mt-4 mb-4">
                      Affordable asset surveillance and management for individuals and business owners.
                    </p>
                  </div>
                </div>
                <form onSubmit={this.handleSubmitPhone.bind(this)} method="POST" className="subscription-form">
                  <div className="form-group">
                    <input
                     value={this.state.phone} 
                      onChange={this.onPhone1Change.bind(this)}
                      type="phone"
                      name="phone"
                      placeholder="(Your Phone Number) Let's call you for a demo"
                      className="form-control"
                    />
                    <button type="submit" className="btn btn-primary">
                      Schedule Call
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-lg-6">
                <img
                  src="assets/img/front.png"
                  alt="front"
                  className="hero-image img-fluid d-none d-lg-block"
                />
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="text-center">
              <h2>Asset Surveillance doesn't have to be that hard! </h2>
              <p className="lead text-muted mt-2">
                TracGlobal provides an intuitive and easy to use platform to enable you track and monitor from anywhere in the world!
              </p>
              <a href="#" className="btn btn-primary">
                Learn More
              </a>
            </div>
            <div className="row">
              <div className="col-lg-7 mx-auto mt-5">
                <img
                  src="assets/img/illustration-1.svg"
                  alt="..."
                  className="intro-image img-fluid"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="bg-primary text-white">
          <div className="container">
            <div className="text-center">
            
                 <center>
                 <div className="col-lg-2">
                    <img
                    src="assets/img/globe.svg"
                    alt="businesses"
                    className="client-image img-fluid"
                  />
                  </div>
                  </center>
                
              <h2>TracGlobal For Your <span style={{color:'#ff0000'}}>Business</span></h2>
              <div className="row">
                <div className="col-lg-9 mx-auto">
                  <p className="lead text-white mt-2">
                    Are you an Enterprise that would like to setup a custom version of your TracGlobal?? We can do that for you!
                  </p>
                </div>
              </div>
              <a href="/businesses" className="btn btn-outline-light">
                Learn More
              </a>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="text-center">
              <h2>Features & Integrations</h2>
              <div className="row">
                <div className="col-lg-8 mx-auto">
                  <p className="lead text-muted mt-2">
                    We strive to provide the best features for our clients. Here are just a few of them. 
                  </p>
                </div>
              </div>
            </div>
            <div className="integrations mt-5">
              <div className="row">
                <div className="col-lg-4">
                  <div className="box text-center">
                    <div className="icon d-flex align-items-end">
                      <img
                        src="assets/img/live.svg"
                        alt="..."
                        className="img-fluid"
                      />
                    </div>
                    <h3 className="h4">Real-Time Tracking</h3>
                    <p className="text-small font-weight-light">
                      See in live mode the current location of your asset.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="box text-center">
                    <div className="icon d-flex align-items-end">
                      <img
                        src="assets/img/fence.svg"
                        alt="..."
                        className="img-fluid"
                      />
                    </div>
                    <h3 className="h4">GeoFencing</h3>
                    <p className="text-small font-weight-light">
                      Set virtual perimeters to give you alerts or perform actions when your asset goes beyond them.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="box text-center">
                    <div className="icon d-flex align-items-end">
                      <img src="assets/img/history.svg" alt="..." className="img-fluid" />
                    </div>
                    <h3 className="h4">History Playback</h3>
                    <p className="text-small font-weight-light">
                      Don't worry if you miss a session. You can always playback to see the history of your assets' movement.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="box text-center">
                    <div className="icon d-flex align-items-end">
                      <img src="assets/img/stolen.svg" alt="..." className="img-fluid" />
                    </div>
                    <h3 className="h4">Anti Theft</h3>
                    <p className="text-small font-weight-light">
                      Tracking reduces by 80% the risk of theft of your asset
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="box text-center">
                    <div className="icon d-flex align-items-end">
                      <img
                        src="assets/img/social-media.svg"
                        alt="..."
                        className="img-fluid"
                      />
                    </div>
                    <h3 className="h4">Multi Channel</h3>
                    <p className="text-small font-weight-light">
                      Recieve immediate notifications from multiple channels including SMS, Email, Web, In-App
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="box text-center">
                    <div className="icon d-flex align-items-end">
                      <img src="assets/img/telegram.svg" alt="..." className="img-fluid" />
                    </div>
                    <h3 className="h4">Social Integration</h3>
                    <code>Extensible*</code>
                    <p className="text-small font-weight-light">
                      We got you covered if you ever need to get access to your notifications right inside your whatsapp or telegram.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-gray">
          <div className="container">
            <div className="text-center">
              <h2>Trusted by </h2>
              <div className="row">
                <div className="col-lg-8 mx-auto">
                  <p className="lead text-muted mt-2">
                    Meet some of our trusted clients
                  </p>
                </div>
              </div>
            </div>
            <div className="clients mt-5">
              <div className="row">
                <div className="col-lg-2">
                  <img
                    src="assets/img/client-1.svg"
                    alt=""
                    className="client-image img-fluid"
                  />
                </div>
                <div className="col-lg-2">
                  <img
                    src="assets/img/client-2.svg"
                    alt=""
                    className="client-image img-fluid"
                  />
                </div>
                <div className="col-lg-2">
                  <img
                    src="assets/img/client-3.svg"
                    alt=""
                    className="client-image img-fluid"
                  />
                </div>
                <div className="col-lg-2">
                  <img
                    src="assets/img/client-4.svg"
                    alt=""
                    className="client-image img-fluid"
                  />
                </div>
                <div className="col-lg-2">
                  <img
                    src="assets/img/client-5.svg"
                    alt=""
                    className="client-image img-fluid"
                  />
                </div>
                <div className="col-lg-2">
                  <img
                    src="assets/img/client-6.svg"
                    alt=""
                    className="client-image img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section>
          <div className="container">
            <div className="text-center">
              <h2>
                Curious how Appton works for <br />
                large organizations?
              </h2>
              <div className="row">
                <div className="col-lg-8 mx-auto">
                  <p className="lead text-muted mt-2">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod. Eiusmod tempor incididunt ut labore.
                  </p>
                </div>
              </div>
              <a href="#" className="btn btn-primary mt-4">
                Get Started
              </a>
            </div>
          </div>
        </section> */}

        <section className="container">
          <div className="container text-center text-lg-left">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <h2 className="divider-heading">
                  You automatically qualify for a free demo!!
                </h2>
                <div className="row">
                  <div className="col-lg-10">
                    <p className="lead divider-subtitle mt-2 text-muted">
                      We will give you a free demo of how our service works even before you pay for anything.
                    </p>
                  </div>
                </div>
                <a href="#" className="btn btn-primary">
                  Learn More
                </a>
              </div>
              <div className="col-lg-5 mt-5 mt-lg-0">
                <img
                  src="assets/img/illustration-2.svg"
                  alt=""
                  className="divider-image img-fluid"
                />
              </div>
            </div>
          </div>
        </section>

        {/* <section className="pb-0">
      <div className="container">
        <div className="text-center">
          <h2>Portfolio</h2>
          <p className="lead text-muted mt-2">You can make also a portfolio or image gallery.</p>
        </div>
        <div className="portfolio mt-5">
          <div className="row">
            <div className="col-lg-4 p-0"><a href="img/portfolio-1.jpg" data-lightbox="image-1" data-title="Some footer information" className="portfolio-item"><img src="img/portfolio-1.jpg" alt="image" className="img-fluid"/></a></div>
            <div className="col-lg-4 p-0"><a href="img/portfolio-2.jpg" data-lightbox="image-1" data-title="Some footer information" className="portfolio-item"><img src="img/portfolio-2.jpg" alt="image" className="img-fluid"/></a></div>
            <div className="col-lg-4 p-0"><a href="img/portfolio-3.jpg" data-lightbox="image-1" data-title="Some footer information" className="portfolio-item"><img src="img/portfolio-3.jpg" alt="image" className="img-fluid"/></a></div>
            <div className="col-lg-4 p-0"><a href="img/portfolio-4.jpg" data-lightbox="image-1" data-title="Some footer information" className="portfolio-item"><img src="img/portfolio-4.jpg" alt="image" className="img-fluid"/></a></div>
            <div className="col-lg-4 p-0"><a href="img/portfolio-5.jpg" data-lightbox="image-1" data-title="Some footer information" className="portfolio-item"><img src="img/portfolio-5.jpg" alt="image" className="img-fluid"/></a></div>
            <div className="col-lg-4 p-0"><a href="img/portfolio-6.jpg" data-lightbox="image-1" data-title="Some footer information" className="portfolio-item"><img src="img/portfolio-6.jpg" alt="image" className="img-fluid"/></a></div>
          </div>
        </div>
      </div>
    </section> */}

        <section className="bg-gray get-started">
          <div className="container text-center">
            <h2>Subscribe to get access to Promotional Offers</h2>
            <div className="row">
              <div className="col-lg-8 m-auto">
                <p className="lead text-muted mt-2">
                 Don't miss out! Opt in for exciting offers and promotions when they're available
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 mx-auto">
                <form action="#" className="subscription-form mt-5">
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email: e.g. yourawesomeemail@gmail.com"
                      className="form-control"
                    />
                    <button type="submit" className="btn btn-primary">
                      Give Me Offers
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
  onPhone1Change(event) {
    this.setState({phone: event.target.value})
  }
}
export default Home;
