import React, { Component } from "react";
import Header from "../DefaultLayout/Header";
import Footer from "../DefaultLayout/Footer";

class FAQs extends Component {
  render() {
    return (
      <div>
        <Header />
        <section className="hero">
          <div className="container text-center">
            <nav
              aria-label="breadcrumb"
              className="d-flex justify-content-center"
            >
              <ol className="breadcrumb">
                <li aria-current="page" className="breadcrumb-item active">
                  FAQs{" "}
                </li>
              </ol>
            </nav>
            <h1 className="hero-heading">
              We'll anwser most of your questions here
            </h1>
            <div className="row">
              <div className="col-lg-7 mx-auto">
                <p className="lead text-muted">
                  You may find most of the answers to questions bothering your
                  mind about our service here. If there's a specific thing you'd
                  like to know about which isn't covered here, head to our{" "}
                  <a href="/contact">contact page</a> or talk to our agents in
                  the chat window below.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <header className="section header mb-5">
              <h2>TracGlobal Frequently Asked Questions</h2>
              <p className="lead">Questions about the service.</p>
            </header>
            <div className="row">
              <div className="col-lg-8">
                <div
                  id="accordion"
                  className="faq accordion accordion-custom pb-5"
                >
                  <div className="card">
                    <div id="headingOne" className="card-header">
                      <h4 className="mb-0 accordion-heading">
                        <button
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                          className="d-flex align-items-center"
                        >
                          <i className="icon-stack-of-sheets"></i>
                          <span>Who can use this service?</span>
                        </button>
                      </h4>
                    </div>
                    <div
                      id="collapseOne"
                      aria-labelledby="headingOne"
                      data-parent="#accordion"
                      className="collapse show"
                    >
                      <div className="card-body">
                        If you own or manage any of the following categories of
                        businesses or any business that involves a fleet of
                        vehicles then TracGlobal is your best shot at helping
                        you save time and expense with a zero maintenance and
                        hassle free solution:
                        <li> Individual car owners </li>
                        <li> Transportation services </li>
                        <li> Taxi services </li>
                        <li> Vehicle/Equipment rentals</li>
                        <li> Delivery/courier services </li>
                        <li> Utilities </li>
                        <li> Maintenance and repair services </li>
                        <li> Security services </li>
                        <li> Sales </li>
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div id="headingTwo" className="card-header">
                      <h4 className="mb-0 accordion-heading">
                        <button
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                          className="d-flex align-items-center collapsed"
                        >
                          <i className="icon-stack-of-sheets"></i>
                          <span> What are some of the benefits?</span>
                        </button>
                      </h4>
                    </div>
                    <div
                      id="collapseTwo"
                      aria-labelledby="headingTwo"
                      data-parent="#accordion"
                      className="collapse"
                    >
                      <div className="card-body">
                        <li>Absolutely NO monthly fees</li>
                        <li>Reduced risk of theft</li>
                        <li>Reduced risk of property misuse/abuse</li>
                        <li>Efficient fuel and cost management</li>
                        <li>24hr/7days Surveillance</li>
                        <li>Full control of vehicle parts/assets</li>
                        <li>Instant resolution of controversies</li>
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div id="headingThree" className="card-header">
                      <h4 className="mb-0 accordion-heading">
                        <button
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          className="d-flex align-items-center collapsed"
                        >
                          <i className="icon-stack-of-sheets"></i>
                          <span>Do you charge a monthly fee?</span>
                        </button>
                      </h4>
                    </div>
                    <div
                      id="collapseThree"
                      aria-labelledby="headingThree"
                      data-parent="#accordion"
                      className="collapse"
                    >
                      <div className="card-body">
                        We are charging <code>0.00ghc</code> for usage of our
                        service which includes SMS, Email, Web and In-App
                        notifications. That's EVERYTHING! 😉. However if you're
                        looking at integrating your default notifications with
                        Whatsapp <i class="fa fa-whatsapp"></i>&nbsp; or
                        Telegram <i class="fa fa-telegram"></i> for easy access
                        and extra convenience, then that comes with an OPTIONAL
                        fee of 10ghc per asset/mo billed annually.
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div id="headingFour" className="card-header">
                      <h4 className="mb-0 accordion-heading">
                        <button
                          data-toggle="collapse"
                          data-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                          className="d-flex align-items-center collapsed"
                        >
                          <i className="icon-stack-of-sheets"></i>
                          <span>
                            How long does it take to setup the service?
                          </span>
                        </button>
                      </h4>
                    </div>
                    <div
                      id="collapseFour"
                      aria-labelledby="headingFour"
                      data-parent="#accordion"
                      className="collapse"
                    >
                      <div className="card-body">
                        Usually it takes about 15 to 30 minutes to setup an
                        asset.
                      </div>
                    </div>
                  </div>
                </div>

                {/* <header className="section-header text-left mb-5 mt-5">
                  <h2 className="mt-5">Troubleshooting</h2>
                  <p className="lead">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore.
                  </p>
                </header> */}
                <div id="accordion2" className="faq accordion accordion-custom">
                  <div className="card">
                    <div id="headingOneAlt" className="card-header">
                      <h4 className="mb-0 accordion-heading">
                        <button
                          data-toggle="collapse"
                          data-target="#collapseOneAlt"
                          aria-expanded="false"
                          aria-controls="collapseOneAlt"
                          className="d-flex align-items-center"
                        >
                          <i className="icon-light-bulb"></i>
                          <span>
                            Can I see in realtime where my car or bike is?
                          </span>
                        </button>
                      </h4>
                    </div>
                    <div
                      id="collapseOneAlt"
                      aria-labelledby="headingOneAlt"
                      data-parent="#accordion2"
                      className="collapse"
                    >
                      <div className="card-body">
                        Yes! The TracGlobal platform provides a map interface
                        for you to be able to visualize and know where you asset
                        is in real time. You can playback history of where your
                        asset has been if you miss anything.
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div id="headingTwoAlt" className="card-header">
                      <h4 className="mb-0 accordion-heading">
                        <button
                          data-toggle="collapse"
                          data-target="#collapseTwoAlt"
                          aria-expanded="true"
                          aria-controls="collapseTwoAlt"
                          className="d-flex align-items-center collapsed"
                        >
                          <i className="icon-plug"></i>
                          <span> How much does it cost?</span>
                        </button>
                      </h4>
                    </div>
                    <div
                      id="collapseTwoAlt"
                      aria-labelledby="headingTwoAlt"
                      data-parent="#accordion2"
                      className="collapse show"
                    >
                      <div className="card-body">
                        A one time fee of <code>Ghc650</code> which includes
                        cost of installation, device, setup of web and mobile
                        platforms(Android + iOS*), notifications(SMS,Email, Web)
                        and a six month warranty.
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div id="headingThreeAlt" className="card-header">
                      <h4 className="mb-0 accordion-heading">
                        <button
                          data-toggle="collapse"
                          data-target="#collapseThreeAlt"
                          aria-expanded="false"
                          aria-controls="collapseThreeAlt"
                          className="d-flex align-items-center collapsed"
                        >
                          <i className="icon-shield-settings"></i>
                          <span>Can I recover my asset if stolen?</span>
                        </button>
                      </h4>
                    </div>
                    <div
                      id="collapseThreeAlt"
                      aria-labelledby="headingThreeAlt"
                      data-parent="#accordion2"
                      className="collapse"
                    >
                      <div className="card-body">
                        Our service enables you to know the location of your
                        asset at any particular point in time. However if you
                        suspect that your asset might have been stolen or the
                        like, it is absolutely urgent that you inform us within
                        the first 24 hours of this incident. We however strongly
                        advice you set things up with your insurance company
                        before you use the service.
                      </div>
                    </div>
                  </div>

                  {/* <div className="card">
                    <div id="headingFourAlt" className="card-header">
                      <h4 className="mb-0 accordion-heading">
                        <button
                          data-toggle="collapse"
                          data-target="#collapseFourAlt"
                          aria-expanded="false"
                          aria-controls="collapseFourAlt"
                          className="d-flex align-items-center collapsed"
                        >
                          <i className="icon-stack-of-sheets"></i>
                          <span>Anim pariatur cliche reprehenderit?</span>
                        </button>
                      </h4>
                    </div>
                    <div
                      id="collapseFourAlt"
                      aria-labelledby="headingFourAlt"
                      data-parent="#accordion2"
                      className="collapse"
                    >
                      <div className="card-body">
                        Anim pariatur cliche reprehenderit, enim eiusmod high
                        life accusamus terry richardson ad squid. 3 wolf moon
                        officia aute, non cupidatat skateboard dolor brunch.
                        Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                        wolf moon tempor, sunt aliqua put a bird on it squid
                        single-origin coffee nulla assumenda shoreditch et.
                        Nihil anim keffiyeh helvetica, craft beer labore wes
                        anderson cred nesciunt sapiente ea proident. Ad vegan
                        excepteur butcher vice lomo
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>

              <aside className="sidebar col-lg-4 mt-5 mt-lg-0">
                {/* <div className="search mb-4">
                  <form action="#" className="search-form">
                    <div className="form-group">
                      <input
                        type="search"
                        name="search"
                        placeholder="Search"
                        className="form-control"
                      />
                      <button type="submit">
                        {" "}
                        <i className="fa fa-search"></i>
                      </button>
                    </div>
                  </form>
                </div> */}
                <div className="sidebar-widget mb-4">
                  <h4 className="sidebar-widget-heading">
                    Questions Categories
                  </h4>
                  <ul className="list-unstyled pl-0 mt-4">
                    <li>
                      {" "}
                      <a href="#" className="categories-link">
                         Tracker nstallation
                      </a>
                    </li>
                    <li>
                      <a href="#" className="categories-link">
                        Pricing
                      </a>
                    </li>
                    <li>
                      <a href="#" className="categories-link">
                        Support
                      </a>
                    </li>
                    <li>
                      <a href="#" className="categories-link">
                        General Information
                      </a>
                    </li>
                    <li>
                      <a href="#" className="categories-link">
                        {/* <!--nothing to put here--> */}
                      </a>
                    </li>
                  </ul>
                </div>
                {/* <div className="sidebar-widget">
                  <h4 className="sidebar-widget-heading">New Tags</h4>
                  <ul className="list-inline pl-0 mt-4">
                    <li className="list-inline-item mr-0">
                      <a href="#" className="tag-link">
                        Design
                      </a>
                    </li>
                    <li className="list-inline-item mr-0">
                      <a href="#" className="tag-link">
                        Window
                      </a>
                    </li>
                    <li className="list-inline-item mr-0">
                      <a href="#" className="tag-link">
                        Science
                      </a>
                    </li>
                    <li className="list-inline-item mr-0">
                      <a href="#" className="tag-link">
                        Mobile App
                      </a>
                    </li>
                    <li className="list-inline-item mr-0">
                      <a href="#" className="tag-link">
                        Ios
                      </a>
                    </li>
                    <li className="list-inline-item mr-0">
                      <a href="#" className="tag-link">
                        Super charge
                      </a>
                    </li>
                  </ul>
                </div> */}
              </aside>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
export default FAQs;
