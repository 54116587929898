import React, { Component } from "react";
// import Drift from "react-driftjs";


class Footer extends Component {
  render() {
    return (
      <footer className="main-footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 mb-5 mb-lg-0">
              <div className="footer-logo">
                <img
                  src="assets/img/logo-footer.png"
                  alt="..."
                  className="img-fluid"
                />
              </div>
              TracGlobal empowers businesses, individuals and fleet managers to
              take total control of their movable properties and get access to
              meaningful and actionable reports about their assets by providing
              them the best and affordable tracking service on the market.
            </div>
            <div className="col-lg-3 mb-5 mb-lg-0">
              <h5 className="footer-heading">Links</h5>
              <ul className="list-unstyled">
                <li>
                  {" "}
                  <a href="/" className="footer-link">
                    Home
                  </a>
                </li>
                <li>
                  {" "}
                  <a href="/faqs" className="footer-link">
                    FAQ
                  </a>
                </li>
                <li>
                  {" "}
                  <a href="/contact" className="footer-link">
                    Contact
                  </a>
                </li>
                <li>
                  {" "}
                  <a href="/app/" className="footer-link">
                    Demo
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 mb-5 mb-lg-0">
              <h5 className="footer-heading">Product</h5>
              <ul className="list-unstyled">
                <li>
                  {" "}
                  <a href="#" className="footer-link">
                    Why TracGlobal?
                  </a>
                </li>
                <li>
                  {" "}
                  <a href="/businesses" className="footer-link">
                    Enterprise
                  </a>
                </li>
                <li>
                  {" "}
                  <a href="#" className="footer-link">
                    Individuals
                  </a>
                </li>
                <li>
                  {" "}
                  <a href="#" className="footer-link">
                    Uber Owners
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3">
              <h5 className="footer-heading">Other Links</h5>
              <ul className="list-unstyled">
                <li>
                  {" "}
                  <a href="#" className="footer-link">
                    Download Brochure
                  </a>
                </li>
                <li>
                  {" "}
                  <a href="#" className="footer-link">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  {" "}
                  <a href="#" className="footer-link">
                    T&C
                  </a>
                </li>
                <li>
                  {" "}
                  <a href="#" className="footer-link">
                    Partners
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="copyrights">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 text-center text-lg-left">
                <p className="copyrights-text mb-3 mb-lg-0">
                  &copy; 2020 All rights reserved | TracGlobal.live{" "}
                </p>
              </div>
              <div className="col-lg-6 text-center text-lg-right">
                <ul className="list-inline social mb-0">
                  <li className="list-inline-item">
                    <a href="#" className="social-link">
                      <i className="fa fa-facebook"></i>
                    </a>
                    <a href="#" className="social-link">
                      <i className="fa fa-twitter"></i>
                    </a>
                    <a href="#" className="social-link">
                      <i className="fa fa-youtube-play"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <Drift appId="spkywxfwtzg3" />; */}

      </footer>
    );
  }
}

export default Footer;
